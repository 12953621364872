export async function useMountedList() {
  const isMobile = useMobileByScreen()
  const { commonData, getCityList } = useCommonStore()
  const { filterConditions } = useFilterConditionsStore()
  const { query } = useRoute()
  const { clearSearch } = useSearchAttr()
  const { locale } = useLocale()

  onUnmounted(() => {
    clearSearch()
  })

  // 更新选中城市
  async function updateSelectedCity() {
    await getCityList()

    filterConditions.selectedCity = commonData.cityInfo?.attrDestinationList?.find(
      (item) => {
        return item.destinationId === Number(query.destinationId)
      },
    ) || {}
  }

  if (!filterConditions.date) {
    filterConditions.date = new Date().getTime()
  }

  if (!filterConditions.selectedCity?.destinationId) {
    updateSelectedCity()
  }

  watch(locale, () => {
    if (filterConditions.selectedCity?.destinationId) {
      updateSelectedCity()
    }
  })

  return {
    isMobile,
  }
}
